import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import LandingPage from "./components/landing/LandingPage";
import ControlPanel from "./components/controlpanel/ControlPanel";
import LoginPage from "./components/auth/LoginPage";
import ServiceRequestForm from "./components/landing/ServiceRequestForm";
import AuthProvider, { AuthContext } from "./context/AuthContext";
import AboutUs from "./components/about-us/AboutUs";
import ReactGA from "react-ga4";

// Google Analytics 4 Page Tracking Hook
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
};

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = React.useContext(AuthContext);
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <AppContent />
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
};

// Separate component to use the useLocation hook
const AppContent = () => {
  usePageTracking(); // Track route changes

  return (
    <>
      <Header companyName="Soriano's Heating and Air Conditioning" />
      <main>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/control-panel"
            element={
              <ProtectedRoute>
                <ControlPanel />
              </ProtectedRoute>
            }
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/request-service" element={<ServiceRequestForm />} />
        </Routes>
      </main>
    </>
  );
};

export default App;
